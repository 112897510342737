<ng-container
  *ngTemplateOutlet="
    customInputTemplate ? customInputTemplate.template : baseInput;
    context: { ref: this, control: control, name: name, disabled: control.disabled, inputPlaceholder: inputPlaceholder }
  "
>
</ng-container>
<ng-template #baseInput>
  <input
    #input
    type="text"
    [attr.id]="inputId"
    [formControl]="control"
    [attr.placeholder]="inputPlaceholder | translate"
    [attr.class]="inputClass"
    (keyup.enter)="input.blur()"
  />
</ng-template>
<ngx-material-timepicker
  #picker
  *ngIf="this.isMobile$ | async"
  [format]="24"
  timepickerClass="mat-timepicker"
  hostClass="mat-timepicker-container"
  [defaultTime]="control.value"
></ngx-material-timepicker>
