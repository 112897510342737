import { Directive, ElementRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: 'ng-template[timeInputControl]',
})
export class TimeInputCustomControlDirective implements OnInit {
  private _inputElement = new BehaviorSubject<ElementRef<HTMLInputElement>>(null);
  readonly inputElement$ = this._inputElement.asObservable();
  get inputElement() {
    return this._inputElement.getValue();
  }
  set inputElement(val: ElementRef<HTMLInputElement>) {
    this._inputElement.next(val);
  }

  constructor(private ref: TemplateRef<any>, private el: TemplateRef<HTMLElement>, private view: ViewContainerRef) {}
  get template() {
    return this.ref;
  }
  ngOnInit(): void {
    if (!this.el?.elementRef?.nativeElement || this.el.elementRef.nativeElement.nodeName === '#comment') return;
    const el = this.el.elementRef.nativeElement.querySelector('input');
    if (el) this.inputElement = new ElementRef(el);
  }
}
